.App {
  text-align: center;
}
.app_header_height{
  height:60px;
  clear: both;
}
.container_roots
{padding: 0px;
  min-height:calc(100vh - 130px);
  margin:auto; 
}
 

.container_size
{
  max-width: 1500px;
  margin:auto;
  padding: 10px;
  display: flow-root;
  clear: both;
 
}
.help-button
{
    float: right;
    cursor: pointer;
    line-height: 30px;
}
.help-button:hover{
  text-decoration: underline;
}
table{
  width: 100%;  
  border-collapse: collapse;
 
 
     font-size: var(--font_size_normal);
     border-radius: 5px; 
     border-collapse: collapse;
 
 }
 /* Global Styles */
h1, h2 {
  color: #333;
}

p, ul, ol, li {
  text-align: left;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  line-height: 25px; 
}

table, th, td {
  border: solid 1px var(--border_color);
  padding: 2px 5px;
 
}

th {
  background-color: var(--layout_background);
}

/* Container Styles */
.container_roots {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}


.body_grid_size {
  padding: 20px;
  line-height: 25px;
}

 
@media (max-width: 1280px) {
  .container_size {
    max-width: 100%;
    margin: 0 auto; /* centers it and prevents horizontal overflow */
    padding: 5px 100px;
    display: flow-root;
    clear: both;
  }
}
 
@media (max-width: 600px) {
  .container_size
  {
    max-width: 1300px;
    margin: auto; 
    padding: 5px;
    display: flow-root;
    clear: both;
  }
}

 
 
.icon {

  color: var(--icon_color);
  height: 24px;
  width: 24px;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0px;
}

.tab {
  display:flow-root;
  align-items: center;
  gap: 2px;
  padding: 10px 2px;
  background-color: transparent;
  color: var(--text_color_info);
  border: 3px solid transparent;
  cursor: pointer;
  transition: 0.3s;
  outline: none;
  line-height: 14px;
  
  font-size: var(--font_size_button);
}

.tab:hover {
  background-color: transparent;
}

.tab.active {
  background-color: transparent;
  color: var(--text_color);
  border: 3px solid transparent;
  border-bottom: solid 2px var(--text_color);
}

.tab-icon {
  
  margin: 6px 5px 6px 1px;
  height: 30px;
  width: 30px;
}
.tab.active.tab-icon 
{
  color: var(--text_color);
}
.tab-content {
  padding: 20px;
  
  min-height: 150px;
  text-align: left;
}

@keyframes pulse {

  0% {

   box-shadow: 0 0 0 0 var(--theme_app_footer_color);

  }

 

  70% {

   box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);

  }

 

  100% {

   box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);

  }

 }
 