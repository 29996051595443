.project_tabs
{
    max-width: 100%;
    min-height: 50px; 
    display: flow-root;
    
}
.project_tabs .date-div
{
    float: right;
    display: flow-root;
}
.project_tabs .date-div .date-input
{
    float: left;
    width: 110px;
    cursor: pointer;
    margin: 3px 5px;
    border-radius: 5px;
    font-size: var(--font_size_normal);
    font-weight: 600;
    height: 20px;
    background-color: var(--layout_background);
    border: solid 1px var(--border_color);
}
.project_tabs .table_tr 
{
background-color: var(--layout_background);
cursor: pointer;
}
.data_div
{
    max-width: 100%;
    padding: 0px;
    margin: 0px;
}