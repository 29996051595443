.settings_root {
    max-width: 1000px;
    margin: 50px auto;
    padding: 20px;
    min-height: 500px;
    display: block; /* Changed from flow-root to block */
    text-align: left;
    background-color: var(--background_color, #fff); /* Added a fallback */
    border-radius: 8px; /* Optional: Adds a smoother UI */
}

.settings_root .header-text {
    font-size: var(--font_size_bg, 24px); /* Added fallback */
    font-weight: 400;
    clear: both;
    color: var(--text_color, #333); /* Added text color */
}
.settings_root .div_settings {
   display: flow-root;
 
    justify-content: space-between; /* Pushes elements apart */
    border-radius: 10px;
    
    margin: 5px 0;
    border: solid 1px var(--border_color);
}
.settings_root .photo_div
{
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background-color: #fff;
    border: solid 3px var(--border_color);
    cursor: pointer;
   
  margin:10px auto;
  position: relative;
}
.settings_root .photo_div .photo_settings {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background-color: #fff; 
    cursor: pointer;
    object-fit: cover;
  position: absolute;
}
.settings_root .table_settings {
   
 width: 100%;
 
    border-radius: 5px; 
   
}
 
.settings_root .table_settings .user-title-text{
  width: 160px; 
    font-weight: 300;
    color: var(--text_color);
} 
.settings_root .table_settings .name_tabel { 
   
    font-weight: 400;
    color: var(--text_color);
}