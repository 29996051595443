.output-body
{
    max-width: 100%;
    clear: both;
    display: none;
}
.output-body .output_div
{ 
outline: none;
font-size: var(--font_size_title);
min-height: 20px;
resize:vertical ;
padding: 10px 10px;
border-radius: 5px;
clear: both;
font-weight: 400;
font-family: Arial, sans-serif;
border: solid 1px var(--border_color);
}
.output-body .output_div .output-url-text
{
  font-weight: 400;
  font-size: var(--font_size_title);
    text-align: left;
}
.output-body .output-btn-div
{ 
outline: none;
font-size: var(--font_size_title);
min-height: 60px;
resize:vertical ;
padding: 10px 0px;
border-radius: 5px;
display: flow-root;
clear: both;

font-weight: 400;
font-family: Arial, sans-serif; 
}
.output-body .output-btn-div .bun-div
{ 
outline: none;
float: left;
font-size: var(--font_size_title);
 
resize:vertical ;
padding: 5px 10px;
border-radius: 5px;
margin: 0px 5px 0px 5px;
cursor: pointer; 
font-weight: 400;
font-family: Arial, sans-serif; 
border: solid 1px var(--border_color);
}
.output-body .output-btn-div .bun-div .img-icon
{
width: 22px;
height: 22px;
float: left;
margin: 3px 0px;
}
.output-body .output-btn-div .bun-div .span-text
{
line-height: 22px;

float: left;
margin: 3px 5px;
}
